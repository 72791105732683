import React from "react";
import "./TeamItem.css";
import { useEffect } from "react";
import gsap from "gsap";
import { FaLinkedin } from "react-icons/fa";



function TeamItemLinkedIn({teamPicture,name, role,linkedIn}){

    useEffect(()=>{
        const target2 = '.item-container div'

        // You can set your initial animation.
        // Like i set opacity 0 for this.
        gsap.set(target2, {
            opacity: 0
        })

        // And here all the div is looping through
        document.querySelectorAll(target2).forEach((eachDiv, index) => {
            gsap.to(eachDiv, {
                immediateRender: true,
                scrollTrigger: {
                    trigger: eachDiv,
                    start: "top 75%",
                    end: "top center",
                    marker: true,
                    scrub: true,
                },
                duration: 1,
                // Here you will set your animation, That need to play when they reached the viewport.
                opacity: 1
            })
        })
    },[])


    return(
        <div className="item-container column is-3 is-12-mobile">
            <div className="picture-circle">
                <img id="team-image" alt="team" src= {teamPicture}/>
            </div>
            <div className="team-role">
                <p className="team-name-text">
                    {name}
                </p>
                <p className="team-role-text">
                    {role}
                    <br/>
                    <a rel="noreferrer" target="_blank" href={linkedIn}>
                        <FaLinkedin  style={{fill : 'white'}}/>
                    </a>
                </p>
               
            </div>

        </div>
    );
}


export default TeamItemLinkedIn;


