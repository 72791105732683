import React from "react";
import "./Team.css";
import TeamItem from "./TeamItem";
import { useEffect } from "react";
import gsap from "gsap";
import dav from "../assets/team-icone/Dav.png";
import ced from "../assets/team-icone/Ced.png";
import daniel from "../assets/team-icone/Daniel.jpeg";
import anass from "../assets/team-icone/Anass.png";
import geek from "../assets/team-icone/image0.jpeg";
import yoko from "../assets/team-icone/Yonko16_091.jpg";
import axel from "../assets/team-icone/axel.jpeg";
import jul from "../assets/team-icone/julian.jpg";
import jothomas from "../assets/team-icone/jo thomas.jpg";
import new1 from "../assets/team-icone/teamnew1.png";
import new2 from "../assets/team-icone/teamnew2.png";
import new3 from "../assets/team-icone/new3.png";
import storm from "../assets/team-icone/jstorm.jpeg";
import TeamItemInsta from "./TeamItemInsta";
import TeamItemInstaTikTok from "./TeamInstaTikTok";
import TeamItemInstaLink from "./TeamItemInstaLink";
import TeamItemLinkedIn from "./TeamItemLinkedIn";
import TeamTwitter from "./TeamTwitter";
import TeamItemLinkedInTwitter from "./TeamItemLinkedInTwitter";
import preloadImage from "./preloadImage";






function Team(){
    useEffect(()=>{
     //   window.addEventListener("mousemove",function(event){
     //       var width = this.window.innerWidth,
      //      height = this.window.innerHeight,
      //      positionX = (event.clientX/width) - 0.55,
      //      positionY = (event.clientY/height) - 0.55;
      //      gsap.to(".team-row1-container",{
      //          rotationY : positionX * 40,
      //          rotationX: -positionY * 40,
      //          ease: "none"
     //       })
     //   })

   //     window.addEventListener("mousemove",function(event){
        //    var width = this.window.innerWidth,
      //      height = this.window.innerHeight,
       //     positionX = (event.clientX/width) - 0.55,
      //     positionY = (event.clientY/height) - 0.55;
     //       gsap.to(".team-row2-container",{
       //         rotationY : positionX * 40,
       //         rotationX: -positionY * 40,
       //         ease: "none"
      //      })
       // })
     //   window.addEventListener("mousemove",function(event){
      //      var width = this.window.innerWidth,
      //      height = this.window.innerHeight,
       //     positionX = (event.clientX/width) - 0.55,
        //    positionY = (event.clientY/height) - 0.55;
       //     gsap.to(".team-row3-container",{
       //         rotationY : positionX * 40,
       //         rotationX: -positionY * 40,
       //         ease: "none"
       //     })
      //  })
        
        /*preloadImage(dav);
        preloadImage(ced);
        preloadImage(daniel);
        preloadImage(anass);
        preloadImage(geek);
        preloadImage(yoko);
        preloadImage(axel);
        preloadImage(jul);
        preloadImage(jothomas);
        preloadImage(new1);
        preloadImage(new2);
        preloadImage(new3);
        preloadImage(storm);*/

        const target2 = '.header-team-container'

        // You can set your initial animation.
        // Like i set opacity 0 for this.
        gsap.set(target2, {
            opacity: 0
        })

        // And here all the div is looping through
        
            gsap.to(target2, {
                immediateRender: true,
                scrollTrigger: {
                    trigger: target2,
                    start: "top 75%",
                    end: "top center",
                    marker: true,
                    scrub: true,
                },
                duration: 1,
                // Here you will set your animation, That need to play when they reached the viewport.
                opacity: 1
            })

        const target3 = '.about-us-container'

        // You can set your initial animation.
        // Like i set opacity 0 for this.
        gsap.set(target3, {
            opacity: 0
        })

        // And here all the div is looping through
        
            gsap.to(target3, {
                immediateRender: true,
                scrollTrigger: {
                    trigger: target3,
                    start: "top 75%",
                    end: "top center",
                    marker: true,
                    scrub: true,
                },
                duration: 1,
                // Here you will set your animation, That need to play when they reached the viewport.
                opacity: 1
            })


        

    },[])

  


    return(
        <div className="team-container">
            <div className="header-team-container">
                <h1 id="team-header">
                    THE TEAM
                </h1>
            </div>
            <div className="about-us-container">
                
                <p>
                Shinsekai is run by a team of successful entrepreneurs, artists, marketers, and crypto & NFT enthusiasts.
                It is their passion for anime and manga that brought them together in this project.
                </p>
            </div>
            <div className="team-row1-container columns is-multiline">
                
                <TeamItemInstaTikTok teamPicture={ced} name={"KASHAMA"} role={"Founder & Designer"} linkInsta={"https://instagram.com/imkashama?utm_medium=copy_link"} linkTikTok={"https://www.tiktok.com/@imkashama?lang=fr"}/>
                <TeamItemInstaLink teamPicture={jul} name={"JULIAN"} role={"Serial Entrepreneur"} linkInsta={"https://instagram.com/anckor?utm_medium=copy_link"} linkedIn={"https://www.linkedin.com/in/julianohayon/"}/>
                <TeamItemInsta teamPicture={dav} name={"BLUE SKY"} role={"Project Manager"} link={"https://www.instagram.com/davidemmanuel_g/"} />
                <TeamItemInstaLink teamPicture={daniel} name={"MISTER D"} role={"Marketer"} linkInsta={"https://www.instagram.com/daniel__duca/"} linkedIn={"https://www.linkedin.com/in/daniel-duca/"} />
                <TeamTwitter teamPicture={storm} name={"Jstorm"} role={"NFT expert"} twitterLink ={"https://twitter.com/Jon__storm"}/>
                <TeamItem teamPicture={anass} name={"BUU"} role={"Creative director"}/>

                <TeamItemLinkedInTwitter teamPicture={jothomas} name={"THOMAS"} role={"Strategy Advisor"} linkTwit= {"https://twitter.com/FOMOBOYZS"} linkedIn={"https://www.linkedin.com/in/thomassalgues"}/>
                <TeamItemLinkedIn teamPicture={geek} name={"JO"} role={"Logistics"} linkedIn={"https://www.linkedin.com/in/jonathan-f-92013aaa/"}/>
                <TeamTwitter teamPicture={new2} name={"YOSHI"} role={"Community manager"} twitterLink={"https://twitter.com/yoshi_mlk"}/> 
                <TeamTwitter teamPicture={yoko} name={"TAC"} role={"Community manager"} twitterLink={"https://twitter.com/M0RNG0ETH"}/>
                <TeamItem teamPicture={axel} name={"NEOCORX"} role={"Discord Advisor"}/>

                <TeamItem teamPicture={new3} name={"LEO"} role={"Full Stack Developer"}/>
                <TeamTwitter teamPicture={new1} name={"M"} role={"Cybersecurity & Smart Contract "} twitterLink={"https://twitter.com/twiceaime"}/>
                <TeamItem teamPicture={jothomas} name={"RYU"} role={"Smart Contract & Script Developer"}/>
                                 
           
                
                
                
                              
            </div>
        </div>
    );
}


export default Team;