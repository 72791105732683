import React from "react";
import "./Timeline.css";

import { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import katanaM from "../assets/katana-mobile.png";
import preloadImage from "./preloadImage";







function Timeline(){
    const text = "THE MANGAVERSE IS ABOUT TO REACH IT'S FINAL STAGE IN 2022";


    

    useEffect(() => {
        preloadImage(katanaM);

        gsap.registerPlugin(ScrollTrigger)

        // const canvas = document.getElementById("katana_canvas");

        // canvas.width = 1200;
        // canvas.height = window.innerHeight/2;

        // function drawCanvasImg(e) {

        //     const image = new Image();
        //     let currentFrame = e.frame * e.endFrame;
        //     let finalFrame = currentFrame.toFixed(0);
        //     console.log(finalFrame);

        //     let finalFinalImage = ''
        //     if (finalFrame <= 9) {
        //         finalFinalImage = '000' + finalFrame
        //     } else if (finalFrame <= 99) {
        //         finalFinalImage = '00' + finalFrame
        //     } else if (finalFrame >= 100) {
        //         finalFinalImage = '0' + finalFrame
        //     }

        //     image.src = images[`${finalFinalImage}.jpg`];

        //     image.onload = function () {
        //         var canvasContext = canvas.getContext('2d');
        //         var wrh = image.width / image.height;
        //         var newWidth = canvas.width;
        //         var newHeight = newWidth / wrh;
        //         if (newHeight > canvas.height) {
        //             newHeight = canvas.height;
        //             newWidth = newHeight * wrh;
        //         }
        //         var xOffset = newWidth < canvas.width ? ((canvas.width - newWidth) / 2) : 0;
        //         var yOffset = newHeight < canvas.height ? ((canvas.height - newHeight) / 2) : 0;

        //         canvasContext.drawImage(image, xOffset, yOffset, newWidth, newHeight);
        //     }

        //     return {
        //         currentFrame: currentFrame,
        //     };
        // }

        gsap.from(".video-katana-container",{
             scrollTrigger: {
                    trigger: ".video-katana-container",
                    start: "top 85%",
                    end: "bottom bottom",
                    marker: true,
                    scrub: true,
            },
            opacity: 0,
            x:-300,
            duration: 5,
        })

        // gsap.to(".timeline-container", {
        //     scrollTrigger: {
        //         trigger: ".timeline-container",
        //         pin: true,
        //         start: "top top",
        //         end: "bottom top",
        //         markers: false,
        //         scrub: true,
        //         onUpdate: function (tl) {
        //             let progress = tl.progress;
        //             drawCanvasImg({
        //                 frame: progress,
        //                 startFrame: 1,
        //                 endFrame: 199,
        //             })
        //         },
        //     },
        // });

        const target2 = '.timeline-header'

        // You can set your initial animation.
        // Like i set opacity 0 for this.
        gsap.set(target2, {
            opacity: 0
        })

        // And here all the div is looping through
        
            gsap.to(target2, {
                immediateRender: true,
                scrollTrigger: {
                    trigger: target2,
                    start: "top 85%",
                    end: "top center",
                    marker: true,
                    scrub: true,
                },
                duration: 2,
                // Here you will set your animation, That need to play when they reached the viewport.
                opacity: 1
            })
        
        
    }, [])


// <canvas  id="katana_canvas" ></canvas>

    return(
        <div className="timeline-container">
                <h2 className="timeline-header">
                    {text}
                </h2>
                <div className="image-timeline-container">
                    <div className="video-katana-container" >
                        <img style={{marginTop:'8%',marginBottom:'6%'}} src={katanaM} alt='katana' />
                    </div>
                </div>
        </div>
    );
}


export default Timeline;