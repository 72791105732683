import React from "react";
import "./Faq.css";
import FaqItem from "./FaqItem";






function Faq(){
    
    const faq1 = "The official launch date of Shinsekai has yet to be announced. However, we do know that it will take place at the end of February 2022. To make sure you don't miss the latest information, join the Discord server.";
    const faq2 = "We have decided to host Shinsekai on the Ethereum blockchain. To offer our community, the most beautiful artworks at the best price, we are going to set the price a bit later.We want to prevent Shinsekai members from suffering from a too high minting price due to Ethereum fluctuations." ;
    const faq3 = "The Mangaverse is our Metaverse. It will allow our community to have a new Manga and Anime experience.The Metaverse is a universal virtual world where everyone is represented by a 3D avatar, and where you can have the same activities as in the real world, such as meeting friends, making new acquaintances, working, gaming, and much more.Shinsekai characters can be used within the Metaverse and our Mangaverse. ";
    
    const faq3bis = "In this 3.0 World you can also buy and sell different products with cryptocurrencies, just like at the grocery store. You will be able to use our NFTs in any Metaverse in .blend, .obj, .fbx, and .glb formats.";
    const real3 = faq3 + faq3bis;
    const faq4 = "If you are a big Anime fan, you might have bought at least a collectible figurine.And be honest, where did it end up? Probably in a box or on a shelf, covered in dust.The worst part is that some of them can cost thousands of dollars. Unlike metal or plastic ones, NFT collectible figurines can be used without getting damaged, and more importantly, without depreciating. And if you’re still having doubts, simply look at the evolution of the anime industry to see that these companies are still rapidly growing ever since their inception.";
    const faq5 = "To join the whitelist and secure your opportunity to acquire a collectible figure, you must meet a few criteria.You will find the steps to follow in our Discord server.Join the community here.";
    const faq6 = "First and foremost, we have decided to create an NFT collection to allow fans of the Shinsekai story to be able to play as their character in the Mangaverse. That being said, your character is also a work of art that can be kept as an investment.Shinsekai members who keep their character will have access to community perks, events, and other surprises.Remember that your NFT can also earn you money by Staking.To learn more, join the Discord server!";
    const faq7 = "We know that for some, Shinsekai will be the first NFT project they invest in. That's why we have put together a collection of videos and tutorials to explain how this world works: how to buy, sell, use your NFTs, as well as how to use OpenSea and MetaMask.You can find all these videos on our Discord server!";

    

    return(
        <div className="faq-page-container">
            <div className="faq-header">
                <h1 className="header-faq">
                    FAQ
                </h1>
            </div>
            <div className="faq-container">
                {/* <FaqItem titleText={"01. When will the official launch take place?"} bodyText={faq1}/> */}
                <FaqItem titleText={"01. What will be the minting cost ?"} bodyText={faq2}/>
                <FaqItem titleText={"02. What is the Mangaverse?"} bodyText={real3}/>
                <FaqItem titleText={"03. Is Shinsekai a good investment?"}bodyText={faq4}/>
                <FaqItem titleText={"04. How do I join the whitelist?"} bodyText={faq5}/>
                <FaqItem titleText={"05. What is the use of my NFT? "} bodyText={faq6}/>
                <FaqItem titleText={"06. I don't know anything about the world of NFTs, where do I start ?"} bodyText={faq7}/>

            </div>
        </div>
    );
}


export default Faq;