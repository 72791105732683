import React, { useEffect, useState } from "react";
import './App.css';
import Header from './components/Header';
import Critere from './components/Critere';
import How7 from './components/How7';
import Team from './components/Team';
import Faq from './components/Faq';
import Footer from './components/Footer';
import Timeline from './components/Timeline';
import RoadMap from './components/RoadMap';
import 'animate.css';
import Universe from './components/Universe';
import AppelAction from './components/AppelAction';
import preloadImageChainNoWait, {preloadImage} from "./components/preloadImage";
import LoadingScreen from './components/loading'


//<Header/>

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const images = importAll(require.context('./assets/frames', false, /\.(png|jpe?g|svg)$/));

function App() {
    //const [loading, setLoading] = useState(true)

    useEffect(() => {
        //setTimeout(() => setLoading(false), 6000);
        let image = new Image()

        image.src = images[`0000.jpg`];

        image.onload = function () {
            var canvas = document.getElementById("global_canvas");
            var canvasContext = canvas.getContext('2d');
            var wrh = image.width / image.height;
            var newWidth = canvas.width;
            var newHeight = newWidth / wrh;
            if (newHeight > canvas.height) {
                newHeight = canvas.height;
                newWidth = newHeight * wrh;
            }
            var xOffset = newWidth < canvas.width ? ((canvas.width - newWidth) / 2) : 0;
            var yOffset = newHeight < canvas.height ? ((canvas.height - newHeight) / 2) : 0;

            canvasContext.drawImage(image, xOffset, yOffset, newWidth, newHeight);
        }

        function loadImage(step, callback) {
            for (var i = 0; i <= 227; i+=step){
                callback(i);
            }
        }

        function loadImages(i) {
            let finalFinalImage = ''
            if (i <= 9) {
                finalFinalImage = '000' + i
            } else if (i <= 99) {
                finalFinalImage = '00' + i
            } else if (i >= 100) {
                finalFinalImage = '0' + i
            }

            // Make sure arr is actually an array and any other error checking
            preloadImage(images[`${finalFinalImage}.jpg`]);
        }

        loadImage(64, loadImages);

    }, [])
    
  return (
      //<>
          //{loading === false ? (
              <div className="App">
                  <Header/>
                  <Critere/>
                  <How7/>
                  <Timeline/>
                  <Universe/>
                  <RoadMap/>
                  <Team/>
                  <br/>
                  <br/>
                  <AppelAction/>
                  {/* <Faq/> */}
                  <Footer/>
              </div>
          //) : (
          //    <LoadingScreen />
          //)}
     // </>
  );
}

export default App;
