import React, { useEffect } from "react";
import "./Critere.css";
import image1 from "../assets/Banner-taille.png";
import image2 from "../assets/comics2.png";
import vidcou from "../assets/vidcou.gif";



import gsap from "gsap";
import preloadImage from "./preloadImage";

//transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(-0.0036deg) rotateY(-0.0032deg) rotateZ(0deg) skew(0deg, 0deg);
function Critere(){

    useEffect(()=>{
        preloadImage(image1);
        preloadImage(image2);
        preloadImage(vidcou);

        const target2 = '.critere-container div'

        // You can set your initial animation.
        // Like i set opacity 0 for this.
        gsap.set(target2, {
            opacity: 0
        })

        // And here all the div is looping through
        document.querySelectorAll(target2).forEach((eachDiv, index) => {
            gsap.to(eachDiv, {
                immediateRender: true,
                scrollTrigger: {
                    trigger: eachDiv,
                    start: "top 75%",
                    end: "top center",
                    marker: true,
                    scrub: true,
                },
                duration: 1,
                // Here you will set your animation, That need to play when they reached the viewport.
                opacity: 1
            })
        })

    },[])

 
    
    const Card = ({image,text}) =>{
        

        return(
            <div className="card-container">
                <div className="img-container">
                    <img id="image" src = {image} alt="image"/>
                </div>
                <div className="text-container">
                    <strong>{text}</strong>
                </div>
            </div>
        );

    }

    const CardVideo = ({gif,text}) =>{
        


        return(
            <div className="card-container">
                <div className="img-container">
                    <img id="vid-cou" src = {gif} alt="image"/>
                </div>
                <div className="text-container">
                    <strong>{text}</strong>
                </div>
            </div>
        );

    }


  

    

    return(
        <div className="critere-container">
            <div className="title-container">
                <h1>
                    THE FINAL STAGE OF MANGA AND ANIME EVOLUTION
                </h1>
            </div>
            <div className=" columns is-1 is-variable is-multiline">
                <div className="column">
                    <Card image={image1} text={"JOIN THE LARGEST FAMILY OF OTAKUS (おたく) AND NFT ENTHUSIASTS"}/>
                </div>
                <div className="column">
                    <CardVideo gif={vidcou} text={"PLAY AS YOUR FAVORITE CHARACTER IN THE METAVERSE"}/>
                </div>
                <div className="column">
                    <Card image={image2} text={"TAKE PART IN THE CREATION OF THE SHINSEKAI MANGA"}/>
                </div>
            </div>
        </div>
    );
}



export default Critere;