import React from 'react'
import "./How7.css";
import Caroussel from './Caroussel';
import { useEffect } from 'react';
import gsap from 'gsap';
//import anim from "../assets/token-flip.mp4";
import anim from "../assets/tokenflip2.gif";
import newAnim from "../assets/deuxd.png";
import preloadImage from "./preloadImage";


function How7(){

    const text1 = " have always been strong pillars of the entertainment industry."
    const text2 = "A team of enthusiasts created "
    const text2bis = " to bring those communities into the Mangaverse and push manga towards the final stage of its evolution."
    const text3 = "Our mission is start by creating the greatest community of Manga, Anime, and NFT enthusiasts, allowing the "
    const text3bis = " brand to grow beyond the NFT space."
    const text8 = "We will publish a manga book, create a Mangaverse, start a clothing brand, then develop other related products revolving around Shinsekai Corp."
    const text4 = "Based on ";
    const text4bis = " our manga book, Kashama has created an NFT collection of 10.000 unique 3D characters."
    const text5 = " Not every character will appear in the manga, but every manga character will be represented in our NFT collection ! ";
    const text5bis = " We want to empower our community members by allowing them to be represented by their favorite characters within the "
    const text6 = "Our entire collection is hosted on the "
    const text7 = "We believe in a new form of collectible avatars and the ";

    useEffect(() => {
        /*preloadImage(anim);
        preloadImage(newAnim);*/

        const target = 'p'

        // You can set your initial animation.
        // Like i set opacity 0 for this.
        gsap.set(target, {
            opacity: 0
        })

        // And here all the div is looping through
        document.querySelectorAll(target).forEach((eachDiv, index) => {
            gsap.to(eachDiv, {
                immediateRender: true,
                scrollTrigger: {
                    trigger: eachDiv,
                    start: "top 75%",
                    end: "top center",
                    marker: true,
                    scrub: true,
                },
                duration: 1,
                // Here you will set your animation, That need to play when they reached the viewport.
                opacity: 1
            })
        })


        const target2 = '.button-mangaverse'

        // You can set your initial animation.
        // Like i set opacity 0 for this.
        gsap.set(target2, {
            opacity: 0
        })

        // And here all the div is looping through
        document.querySelectorAll(target2).forEach((eachDiv, index) => {
            gsap.to(eachDiv, {
                immediateRender: true,
                scrollTrigger: {
                    trigger: eachDiv,
                    start: "top 75%",
                    end: "top center",
                    marker: true,
                    scrub: true,
                },
                duration: 1,
                // Here you will set your animation, That need to play when they reached the viewport.
                opacity: 1
            })
        })

        const target3 = '.header1-container'

        // You can set your initial animation.
        // Like i set opacity 0 for this.
        gsap.set(target3, {
            opacity: 0
        })

        // And here all the div is looping through
        document.querySelectorAll(target3).forEach((eachDiv, index) => {
            gsap.to(eachDiv, {
                immediateRender: true,
                scrollTrigger: {
                    trigger: eachDiv,
                    start: "top 75%",
                    end: "top center",
                    marker: true,
                    scrub: true,
                },
                duration: 1,
                // Here you will set your animation, That need to play when they reached the viewport.
                opacity: 1
            })
        })

    }, [])

    return(
        <div className='how7-container'>
            <div className='all-page-container'>
                    <div className='text1-container columns'>
                        <div className="column p-6 screenseven">
                            <div className='header1-container'>
                                <h2>
                                    HOW 7 UNIVERSE WILL REVOLUTIONIZE MANGA & ANIME INDUSTRY
                                </h2>
                            </div>
                        
                            <p className='first-text-paragraph'>
                                <strong> Manga & anime </strong>
                                {text1}
                                <br/>
                                <br/>
                                {text2}
                                <strong>Shinsekai</strong>
                                {text2bis}
                                <br/>
                                <br/>
                                {text3}
                                <strong>Shinsekai</strong>
                                {text3bis}
                                <br/>
                                <br/>
                                {text8}
                            </p>
                            <div className='button-mangaverse'>
                                <a id='but-mangaverse' rel='noreferrer' target="_blank" href='https://t.co/bUper2SbeI' >
                                    JOIN THE MANGAVERSE
                                </a>
                            </div>
                        </div>
                        <div className="column">
                                <div className='caroussel-container'>
                                    <Caroussel animation={anim}/>
                                </div>
                            </div>
                        </div>
                    <div className='text2-container mt-6 columns p-6'>
                    <div className="column is-hidden-mobile">
                            <div className='caroussel2-container'>
                            <Caroussel animation={newAnim}/>
                            </div>
                        </div>
                        <div className="column textsecond">
                            <p className='titles'>
                                <strong>10 000 COLLECTIBLES</strong>
                                <br/>
                                <br/>
                            </p>
                            <p className='titles-c'>
                                {text4}
                                <strong>Fukiyose,</strong>
                                {text4bis}
                                {/* <strong> Shinsekai</strong> */}
                                {text5}
                                <br/>
                                <br/>
                                {/* <strong>unique</strong> */}
                                {text5bis}
                                <strong>Shinsekai Mangaverse.</strong>

                                <br/>
                                {text6}
                                <strong>Ethereum blockchain.</strong>
                            </p>   
                            <div className='text3-container'>
                                <p className='titles-c'>
                                    <strong> Joining Shinsekai means being part of web 3.0's largest Manga & Anime family.</strong>
                                </p>
                                <p>
                                    {text7}
                                    <strong>evolution of anime.</strong>

                                </p>
                            </div>
                        </div>
                     
                    </div>
                      
                </div>
               
            </div>
           
    
    );
}


export default How7;


