import React from "react";
import "./Footer.css";
import { FaDiscord,FaInstagram,FaTwitter} from "react-icons/fa";
import term from "../assets/termsandconditionsshinsekai.pdf"
import { Link } from "react-router-dom";

function Footer(){
    return(
        <div className="footer-container">
            <div className="social-footer">
                <a rel="noreferrer" target="_blank" href="https://www.instagram.com/shinsekaicorp/">
                    <FaInstagram  style={{fill : 'white'}}/>
                </a>
                <a rel="noreferrer" target="_blank" href="https://t.co/bUper2SbeI">
                    <FaDiscord  style={{fill : 'white'}}/>
                </a>
                <a rel="noreferrer" target="_blank" href="https://twitter.com/shinsekaicorp">
                    <FaTwitter  style={{fill : 'white'}}/>
                </a>

            </div>
            <div className="footer-text">
                2022 ShinSekaiCorp | All rights reserved 
            </div>
            <div className="termConditions-text">
                <a rel="noreferrer" target="_blank" href={term}>
                   Shinsekai Term & Conditions
                </a> 
            </div>
        </div>
    );
}


export default Footer;