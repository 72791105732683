import React from 'react'
import "./Caroussel.css";

import { useEffect } from "react";
import gsap from "gsap";


function Caroussel({animation}) {

    useEffect(() => {
        const target = '.caroussel-animation-container'

        // You can set your initial animation.
        // Like i set opacity 0 for this.
        gsap.set(target, {
            opacity: 0
        })

        // And here all the div is looping through
        document.querySelectorAll(target).forEach((eachDiv, index) => {
            gsap.to(eachDiv, {
                immediateRender: true,
                scrollTrigger: {
                    trigger: eachDiv,
                    start: "top 75%",
                    end: "top center",
                    marker: true,
                    scrub: true,
                },
                duration: 1,
                // Here you will set your animation, That need to play when they reached the viewport.
                opacity: 1
            })
        })
    },[])

    return(
        <div className="caroussel-animation-container">
            {/* <video id='video-anim-flip' style={{maxWidth : '80%'}} preload='yes' autoPlay muted playsInline loop >
                <source src={animation} type= "video/mp4"/>
            </video> */}
            <img src={animation} style={{maxWidth : '85%'}} alt='video-anim'/>
        </div>
    );
}


export default Caroussel;