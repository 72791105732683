import React from "react";
import "./Universe.css";
import { useEffect,useState } from "react";
import abyss from "../assets/universe/Abyss.png";
import celestial from "../assets/universe/celestial.png";
import human from "../assets/universe/human.png";
import vempire from "../assets/universe/vempire.png";
import bunes from "../assets/universe/bunes.png";
// import notRev2 from '../assets/universe/notReveal.png';
import demon from "../assets/universe/demon.png";
import notRev3 from '../assets/universe/notReveal.png';
import preloadImage from "./preloadImage";



const CloseOverly = () => {
    return (
        <div className="close_overly" >
            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="2.5em" width="2.5em" xmlns="http://www.w3.org/2000/svg"><path d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z"></path></svg>
        </div>
    )
}

function Universe(){

    useEffect(() =>{
        /*preloadImage(abyss);
        preloadImage(celestial);
        preloadImage(human);
        preloadImage(vempire);
        preloadImage(bunes);
        preloadImage(demon);
        preloadImage(notRev3);*/
    },[]);


    const circles = [
        {
            imgSrc: human,
            description: 'Humans are common inhabitants of the cosmos, similar to the humans we know. They can be found on many worlds, but Aranos is the place where they can be found the most. Their great number is their main asset, but their tendency to be divided makes them a minor power in the eyes of other races.',
            titre : 'HUMANS (にんげん)'
        },
        {
            imgSrc: vempire,
            description: "Vampires are divided into several large families, the most famous of which is the Von Dracula family. The latter is both feared and respected by its fellow vampires. Although vampires can live under the sun, their powers are weakened considerably. They prefer to live at night, which causes them to develop a somewhat morbid and cantankerous character. Vampires can be found in many worlds, although they do not identify any of these as their their own.",
            titre : 'VAMPIRES (きゅうけつき)'
        },
        {
            imgSrc: abyss,
            description: 'The people of the abyss are made up of legendary beings capable of breathing underwater through the use of aquatic attributes. They are a proud and friendly race. It is rare to see a Ketos outside of their world, as they are not inclined to new experiences. They are one of the few races to have made a pact with the Seraphim.',
            titre : 'ABYSS (けと)'
        },
        {
            imgSrc: bunes,
            description: 'Mysterious beings originating from an unknown place. Legend has it that these creatures come from the world of the dead to punish mortals. Their sole purpose is to destroy the living. Initially, these creatures had no definite form, however today their bodies have a humanoid shape. Their powers are the opposite of creation, everything is based on chaos !',
            titre : 'UTSURO-BUNES (うつろぶね)'
        },
        {
            imgSrc: celestial,
            description: 'Seraphim are beings with the appearance of angels. They are in direct competition with the celestials for the status of divinity. Proud by nature, they help the "crawling people" who must be guided by their purity. They claim to be root cause of the creation of the portals that appeared throughout the different worlds. The only people with whom the Seraphim have made a pact are the Abyssal.',
            titre : 'SERAPHIM (セラフ)'
        },
        {
            imgSrc: demon,
            description: 'The demons are former Seraphim who rejected their origin. Banished by their fellow Seraphim, they were locked up by them in the depths of the abyss inside a giant prison, which was entrusted to the Ketos in exchange of favors. Their imprisonment made their resentment and hatred grow, transforming them little by little into something similar to the Seraphim but fundamentally different.',
            titre : 'DEMONS (おに)'
        },
        {
            imgSrc: notRev3,
            description: 'This universe will be revealed soon...',
            titre : 'SHINS (いんじゃ)'
        },
        // {
        //     imgSrc: notRev3,
        //     description: 'The world has always known change, evolution, and progress. When what usually takes decades or even centuries takes place over a few months or days, a whole realm of certainties collapses. The appearance of several windows into other worlds incites fear, excitement, and greed. People’s self-interests are wiped away by geopolitics and power plays. When these windows start causing mutations to the populace and environment, panic sets in. Panic turns into mass hysteria as these windows are revealed to be portals, giving an opening into other worlds filled with fantastic creatures and unknown technologies. All these events are catalyzed around Roy, a young geopolitical refugee. His curious abilities and the strange connection he seems to have with the portals thrust him at the center of the mystery. Does he hold the key to all these intriguing events ? The cogs of destiny are set in motion and they will grind everything down in their path... ',
        //     titre : 'HERMITS (いんじゃ)'
        // }
    ]
    
    const [currentlyShowing, setCurrentlyShowing] = useState(0)
    const toggleSquare = (i) => setCurrentlyShowing(prev => prev === i ? null : i)

    // useEffect(()=>{
    //    // const target2 = '.circles_wrapper'
    //     const target3 = '.header'

    //     // You can set your initial animation.
    //     // Like i set opacity 0 for this.
    //     // gsap.set(target2, {
    //     //     opacity: 0
    //     // })

    //     // // And here all the div is looping through
    //     // document.querySelectorAll(target2).forEach((eachDiv, index) => {
    //     //     gsap.to(eachDiv, {
    //     //         scrollTrigger: {
    //     //             trigger: eachDiv,
    //     //             start: "top top",
    //     //             end: "top center",
    //     //             marker: true,
    //     //             scrub: true,
    //     //         },
    //     //         duration: 1,
    //     //         // Here you will set your animation, That need to play when they reached the viewport.
    //     //         opacity: 1
    //     //     })
    //     // })

    //     // gsap.set(target3, {
    //     //     opacity: 0
    //     // })

    //     // And here all the div is looping through
    //     document.querySelectorAll(target3).forEach((eachDiv, index) => {
    //         gsap.to(eachDiv, {
    //             scrollTrigger: {
    //                 trigger: eachDiv,
    //                 start: "top 85%",
    //                 end: "top center",
    //                 marker: true,
    //                 scrub: true,
    //             },
    //             duration: 1,
    //             // Here you will set your animation, That need to play when they reached the viewport.
    //             opacity: 1
    //         })
    //     })
    // },[])

    return(
        <div className="universe-container">
            <div className="header1">
                <h1>
                    THE 7 UNIVERSES DISCOVERED
                </h1>
            </div>
            <>
            <section className="gap_section-up" ></section>
            <section className="container_wrapper">
                {/* Main Component --Start-- */}
                <div className="circles_wrapper" >
                    {circles.map(({ imgSrc, description,titre }, index) => (
                        <div key={index} className="circle_wrapper"
                        >
                            <div 
                                className={`square_box_wrapper ${currentlyShowing === index ? '_show_square' : ''}`}>
                                <div className="square">
                                    <img src={imgSrc} alt="universe" />
                                </div>
                                <div className="description_box">
                                    <p id="uni-text" style={{color:'white',fontFamily : 'SpaceGrotesk',marginBottom : '5%'}}>
                                        <strong>{titre}</strong>
                                     </p>
                                     <p>
                                        {description}
                                    </p>
                                </div>
                            </div>
                            <div className="circle" onClick={() => toggleSquare(index)}>
                                {currentlyShowing === index && <CloseOverly />}
                                <img src={imgSrc} alt="" />
                            </div>
                        </div>
                    ))}
                </div>
                {/* Main Component --End-- */}

            </section>
            <section className="gap_section-bottom" ></section>
        </>
        </div>
    );
}



export default Universe;


